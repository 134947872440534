import {getFormattedFullLocation} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import React from 'react'
import {membersPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useEventDateInformation} from '../../../../../commons/hooks/dates'
import {getEventOrders, hasOrder} from '../../selectors/event'
import {OrderDetails} from '../order-details'
import {EventText} from '../styled-components/event-text'
import s from './event-details.scss'
import {EventDetailsProps} from '.'

export const EventDetails = ({event, mobile, primaryButton, secondaryButton, shareButton}: EventDetailsProps) => {
  const {startDate} = useEventDateInformation(event.event.id)

  return (
    <>
      <div className={classNames(s.details, {[s.mobile]: mobile})}>
        <div>
          <EventText faded extraClass={s.infoText} dataHook={DH.START_DATE}>
            {startDate}
          </EventText>
        </div>
        <div className={s.location}>
          <EventText faded extraClass={s.infoText} dataHook={DH.FULL_LOCATION}>
            {getFormattedFullLocation(event.event)}
          </EventText>
        </div>
        <div className={s.actions}>
          <div className={s.leftActions}>
            {primaryButton}
            <div className={s.actionButton}>{secondaryButton}</div>
          </div>
          {shareButton}
        </div>
      </div>
      {hasOrder(event) && !mobile
        ? getEventOrders(event).map(order => <OrderDetails key={order.orderNumber} order={order} event={event} />)
        : null}
    </>
  )
}
