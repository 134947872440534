export const getTicketsWithoutTax = (order: wix.events.ticketing.Order) =>
  order.tickets.map(ticket => ({...ticket, price: getTicketPriceWithoutTax(order, ticket)}))

export const getTicketPriceWithoutTax = (
  order: wix.events.ticketing.Order,
  ticket: wix.events.ticketing.Ticket,
): wix.events.Money => {
  const amount =
    ticket.ticketDetails?.priceOverride ??
    order.invoice?.items?.find(item => item.id === ticket.ticketDefinitionId)?.price?.amount
  const currency = ticket.price?.currency
  return amount ? {amount, currency} : undefined
}
